exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-transformation-js": () => import("./../../../src/pages/digital_transformation.js" /* webpackChunkName: "component---src-pages-digital-transformation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobedo-story-js": () => import("./../../../src/pages/mobedo_story.js" /* webpackChunkName: "component---src-pages-mobedo-story-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-shared-services-js": () => import("./../../../src/pages/shared_services.js" /* webpackChunkName: "component---src-pages-shared-services-js" */),
  "component---src-pages-starategy-js": () => import("./../../../src/pages/starategy.js" /* webpackChunkName: "component---src-pages-starategy-js" */),
  "component---src-pages-strategy-js": () => import("./../../../src/pages/strategy.js" /* webpackChunkName: "component---src-pages-strategy-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

